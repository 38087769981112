<template>
    <div>
        <el-row>
            <el-form v-model="params" :inline="true" label-position="right" label-width="110px" size="mini">
                <div class="transition-box">
                    <!--                    <el-form-item label="状态" prop="status">-->
                    <!--                        <el-select v-model="params.status">-->
                    <!--                            <el-option value="" label="全部"></el-option>-->
                    <!--                            <el-option v-for="item in statusList" :value="item.value" :label="item.label"></el-option>-->
                    <!--                        </el-select>-->
                    <!--                    </el-form-item>-->
                    <el-form-item label="班主任" prop="nickName">
                        <el-input v-model="params.nickName" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="服务号" prop="servicesId">
                        <el-select v-model="params.servicesId" filterable style="width: 100%;">
                            <el-option v-for="item in serviceList" :key="item.servicesId" :label="item.name"
                                       :value="item.servicesId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="服务组" prop="servicesCrowdId">
                        <el-select v-model="params.servicesCrowdId" filterable style="width: 100%;">
                            <el-option v-for="item in crowdList" :key="item.crowdId" :label="item.name"
                                       :value="item.crowdId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-row>
        <el-row>
            <el-col :span="18">
                <el-button type="primary" icon="el-icon-plus" size="mini" v-permission="'waiter:teacher:add'"
                           @click="handleAdd">新增
                </el-button>
            </el-col>
            <el-col :span="6">

            </el-col>
        </el-row>
        <el-table :data="dataList" style="width: 100%" stripe fit max-height="640" v-loading="loading">
            <el-table-column align="center" label="主键" prop="id"></el-table-column>
            <el-table-column align="center" label="名称" prop="nickName"></el-table-column>
            <el-table-column align="center" label="默认服务号" prop="servicesId">
                <template v-slot="scope">
                    <span v-for="item in serviceList" :key="item.servicesId"
                          v-if="item.servicesId === scope.row.servicesId">
                        {{ item.name }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="归属服务组" prop="servicesCrowdId">
                <template v-slot="scope">
                    <span v-for="item in crowdList" :key="item.crowdId"
                          v-if="item.crowdId === scope.row.servicesCrowdId">
                        {{ item.name }}
                    </span>
                </template>
            </el-table-column>
            <!--            <el-table-column align="center" label="所属团队" prop="deptId"></el-table-column>-->
            <el-table-column align="center" label="配置" prop="teacherConfig">
                <template v-slot="scope">
                    <span v-for="item in configureList" :key="item.configureId"
                          v-if="item.configureId === scope.row.teacherConfig">
                        {{ item.name }}
                    </span>
                </template>
            </el-table-column>
            <!--            <el-table-column align="center" label="状态" prop="status">-->
            <!--                <template v-slot="scope">-->
            <!--                    <span v-for="item in statusList" v-if="scope.row.status === item.value">{{ item.label }}</span>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column align="center" label="操作">
                <template v-slot="scope">
                    <el-button type="text" size="mini" icon="el-icon-edit-outline" v-permission="'waiter:teacher:edit'"
                               @click="handleUpdate(scope.row)">
                        修改
                    </el-button>
                    <el-button type="text" size="mini" icon="el-icon-delete"
                               v-permission="'waiter:teacher:delete'" @click="handleDelete(scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="findPage"
                @current-change="findPage"
                :current-page.sync="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size.sync="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <el-dialog :title="dataFromAdd?'添加数据':'修改数据'" :visible.sync="dataFromSync" width="60%">
            <el-form :model="dataFrom" :rules="dataFromRules" ref="dataFrom" style="width: 400px" label-width="100px"
                     size="medium">
                <el-form-item label="选择账号" prop="id">
                    <el-select v-model="dataFrom.id" filterable style="width: 100%;">
                        <el-option v-for="item in accountList"
                                   v-if="(dataFromAdd && item.status === 1) || !dataFromAdd"
                                   :disabled="item.status !== 1" :key="item.id" :label="item.nickName"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="配置项" prop="teacherConfig">
                    <el-select v-model="dataFrom.teacherConfig" filterable style="width: 100%;">
                        <el-option v-for="item in configureList" :key="item.configureId" :label="item.name"
                                   :value="item.configureId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="默认服务号" prop="servicesId">
                    <el-select v-model="dataFrom.servicesId" filterable style="width: 100%;">
                        <el-option v-for="item in serviceList" v-if="!item.status" :key="item.servicesId" :label="item.name"
                                   :value="item.servicesId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="归属服务组" prop="servicesCrowdId">
                    <el-select v-model="dataFrom.servicesCrowdId" filterable style="width: 100%;">
                        <el-option v-for="item in crowdList" :key="item.crowdId" :label="item.name"
                                   :value="item.crowdId"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" @click="submitForm('dataFrom')">确 定</el-button>
                <el-button size="medium" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as configureApi from "@/api/waiter/waiterConfigure"
import * as accountApi from "@/api/system/account"
import * as serviceApi from "@/api/waiter/waiterServices";
import * as crowdApi from "@/api/waiter/waiterServicesCrowd";

export default {
    name: "WaiterClassTeacher",
    data() {
        return {
            statusList: [{label: '正常', value: 0}, {label: '禁用', value: 9}],
            dataFrom: {
                teacher: true,
            },
            dataFromRules: {
                id: [{required: true, message: '请选择账号', trigger: 'blur'}],
                teacherConfig: [{required: true, message: '请设置配置项', trigger: 'blur'}],
                servicesId: [{required: true, message: '请设置默认服务号', trigger: 'blur'}],
                servicesCrowdId: [{required: true, message: '请设置归属服务组', trigger: 'blur'}],
            },
            dataFromSync: false,
            dataFromAdd: true,
            params: {
                current: 1,
                size: 10,
            },
            loading: false,
            dataList: [],
            total: 0,
            idList: [],
            accountList: [],
            configureList: [],
            serviceList: [],
            crowdList: [],
        }
    },
    mounted() {
        this.findPage()
        this.findAccount()
        this.findConfigure()
        this.findService()
        this.findCrowdList()
    },
    methods: {
        findCrowdList() {
            crowdApi.findList().then(res => {
                if (res.success) {
                    this.crowdList = res.data
                }
            })
        },
        findService() {
            serviceApi.findList().then(res => {
                if (res.success) {
                    this.serviceList = res.data
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        findConfigure() {
            configureApi.findList().then(res => {
                if (res.success) {
                    this.configureList = res.data
                }
            })
        },
        findAccount() {
            accountApi.list().then(res => {
                if (res.success) {
                    this.accountList = res.data
                }
            })
        },
        /**
         * 取消
         */
        cancel() {
            this.dataFromSync = false
        },
        /**
         * 保存
         */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    accountApi.saveTeacher(this.dataFrom).then(res => {
                        if (res.success) {
                            this.$message({
                                type: "success",
                                message: '保存成功！'
                            });
                            this.dataFromSync = false
                            this.findPage()
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg
                            });
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        handleDelete(row) {
            let data = {
                id: row.id,
                teacher: false,
                teacherConfig: 0,
                crowdId: 0
            }
            accountApi.saveTeacher(data).then(res => {
                if (res.success) {
                    this.$message({
                        type: "success",
                        message: '停用成功！'
                    });
                    this.findPage()
                } else {
                    this.$message({
                        type: "error",
                        message: res.msg
                    });
                }
            })
        },
        /**
         * 修改
         * @param row
         */
        handleUpdate(row) {
            this.dataFromSync = true
            this.dataFromAdd = false
            this.dataFrom = Object.assign({}, row)
        },
        /**
         * 添加
         */
        handleAdd() {
            this.dataFromSync = true
            this.dataFromAdd = true
            this.dataFrom = {
                teacher: true
            }
        },
        /**
         * 重置
         */
        resetQuery() {
            this.params = {
                current: 1,
                size: 10
            }
            this.doSearch()
        },
        /**
         * 点击搜索
         */
        doSearch() {
            this.params.current = 1
            this.findPage()
        },
        /**
         * 分页查询数据
         */
        findPage() {
            this.loading = true
            accountApi.findTeacherPage(this.params).then(res => {
                this.loading = false
                if (res.success) {
                    this.dataList = res.data.records
                    this.total = res.data.total
                }
            })
        },
    }
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>